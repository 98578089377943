import React from "react"
import styles from "./styles.module.scss"

const MetaData = ({ author, date, big }) => {
    return (
        <div className={`${styles.metaDataWrapper} ${big && styles.big}`}>
            {author} • {date}
        </div>
    )
}

export default MetaData
