import React from "react"
import { Header, Footer } from "components/organisms"
import styles from "./styles.module.scss"

const Layout = ({ children }) => {
    return (
        <div className={styles.layout}>
            <Header />
            <main className={`container ${styles.contentWrapper}`}>
                {children}
            </main>
            <Footer />
        </div>
    )
}

export default Layout
