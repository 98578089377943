import React, { useState } from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"
import { TagTextList, MetaData } from "components/molecules"
import styles from "./styles.module.scss"

const BlogPreview = ({ large, post }) => {
    const [hover, setHover] = useState(false)

    const {
        node: {
            fields: { slug },
            excerpt,
            frontmatter: { author, title, description, date, tags, img },
        },
    } = post

    const previewTitle = title || slug
    const previewDescription = description || excerpt
    const fluidImage = img ? img.childImageSharp.fluid : ""

    return (
        <div
            className={`
                ${styles.blogPreviewContainer}
                ${large && styles.large + " flex-column"}
                row
            `}
        >
            <div className={`${styles.large ? "col" : "col-md-6 "}`}>
                <Link
                    className={`${styles.imgWrapper} ${
                        hover ? styles.hovered : ""
                    }`}
                    onMouseEnter={() => setHover(true)}
                    onMouseLeave={() => setHover(false)}
                    to={slug}
                >
                    <Img
                        className={styles.img}
                        fluid={{
                            ...fluidImage,
                            aspectRatio: large ? 24 / 9 : 16 / 9,
                        }}
                    />
                </Link>
            </div>
            <div className={`${large ? "col-lg-8" : "col-md-6"}`}>
                <div className={`${styles.textContainer}`}>
                    <TagTextList tags={tags} />
                    <Link
                        className={`${styles.title} ${
                            hover ? styles.hovered : ""
                        }`}
                        onMouseEnter={() => setHover(true)}
                        onMouseLeave={() => setHover(false)}
                        to={slug}
                    >
                        {large ? (
                            <h2 className={styles.header}>{previewTitle}</h2>
                        ) : (
                            <h3 className={styles.header}>{previewTitle}</h3>
                        )}
                    </Link>
                    <MetaData author={author} date={date} />
                    <div className={styles.description}>
                        {previewDescription}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BlogPreview
