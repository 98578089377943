import React from "react"
import { Tag } from "components/atoms"
import styles from "./styles.module.scss"

const TagTextList = ({ tags }) => {
    return (
        <ul className={styles.tagContainer}>
            {tags.map((tag, i) => (
                <li key={tag}>
                    <Tag tag={tag} />
                </li>
            ))}
        </ul>
    )
}

export default TagTextList
