import React from "react"
import { CtaLink } from "components/atoms"
import styles from "./styles.module.scss"

const PrevNext = ({
    children,
    isFirst,
    isLast,
    nextLink,
    nextTitle,
    previousLink,
    previousTitle,
}) => {
    const prevLinkBool = !isFirst && previousLink
    const nextLinkBool = !isLast && nextLink

    return (
        <ul className={styles.prevNextWrapper}>
            <li>
                <CtaLink
                    direction={"left"}
                    className={`${!prevLinkBool && styles.disabled}`}
                    href={prevLinkBool && previousLink}
                >
                    {previousTitle}
                </CtaLink>
            </li>

            <div className={styles.children}>{children}</div>

            <li>
                <CtaLink
                    direction={"right"}
                    className={`${!nextLinkBool && styles.disabled}`}
                    href={nextLinkBool && nextLink}
                >
                    {nextTitle}
                </CtaLink>
            </li>
        </ul>
    )
}

export default PrevNext
