import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import { Twitter } from "react-feather"
import styles from "./styles.module.scss"

const Header = () => {
    const {
        site: {
            siteMetadata: { title },
        },
    } = useStaticQuery(graphql`
        query {
            site {
                siteMetadata {
                    title
                }
            }
        }
    `)

    return (
        <header className={styles.headerContainer}>
            <div className={"container"}>
                <div
                    className={`
                        ${styles.header}
                        row
                        align-items-end
                `}
                >
                    <div
                        className={`
                            ${styles.socialWrapper}
                            col-md-1
                        `}
                    >
                        <a
                            className={styles.socialLink}
                            href="https://twitter.com/justinsunho"
                        >
                            <Twitter strokeWidth="0" fill="#000" />
                        </a>
                    </div>

                    <h1 className={`${styles.headerTitle} col-md-10`}>
                        <Link to={`/`}>{title}</Link>
                    </h1>

                    <div
                        className={`
                            ${styles.nav}
                            col-md-1
                            d-flex
                            align-items-md-end
                            align-items-start
                            flex-md-column
                            flex-row
                            flex-lg-row
                            justify-content-md-end
                        `}
                    >
                        <Link className={`${styles.navItem}`} to={`/blog`}>
                            archive
                        </Link>
                        <Link className={`${styles.navItem} `} to={`/about`}>
                            about
                        </Link>
                    </div>
                </div>
            </div>
        </header>
    )
}

export default Header
