import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { Tag } from "components/atoms"
import styles from "./styles.module.scss"

const TagButtonList = () => {
    const {
        allMarkdownRemark: { group },
    } = useStaticQuery(graphql`
        query {
            allMarkdownRemark(
                sort: { fields: [frontmatter___date], order: DESC }
                limit: 1000
            ) {
                group(field: frontmatter___tags) {
                    tag: fieldValue
                    totalCount
                }
            }
        }
    `)

    return (
        <div className={styles.tagListWrapper}>
            <h3 className={styles.title}>Tags</h3>
            <ul className={styles.listWrapper}>
                {group.map(({ tag }) => (
                    <li className={styles.listItem} key={tag}>
                        <Tag tag={tag} button />
                    </li>
                ))}
            </ul>
        </div>
    )
}

export default TagButtonList
