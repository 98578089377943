import React from "react"
import { Link } from "gatsby"
import utilities from "theme/utilities.module.scss"
import styles from "./styles.module.scss"

const Tag = ({ tag, button }) => {
    return (
        <Link
            className={`
                ${button && styles.button}
                ${utilities.smallCaps}
            `}
            to={`/tag/${tag.replace(" ", "-")}`}
        >
            {tag}
        </Link>
    )
}

export default Tag
