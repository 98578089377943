import React from "react"
import { Link } from "gatsby"
import styles from "./styles.module.scss"

const CtaLink = ({ direction, children, className, href }) => {
    return (
        <Link className={`${className} ${styles.link}`} to={href}>
            {direction === "left" && (
                <span className={styles.leftArrow}>←</span>
            )}
            <span classNam={styles.textContainer}>{children}</span>
            {direction === "right" && (
                <span className={styles.rightArrow}>→</span>
            )}
        </Link>
    )
}

export default CtaLink
